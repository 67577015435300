@charset "utf-8";
.cookie__popup {
    width: calc(100% - 40px);
    max-width: 480px;
    padding: 20px;
    box-sizing: border-box;
    background: rgba(0,0,0,.8);
    border: 2px solid #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 2px rgba(0,0,0,.5);
    position: fixed;
    bottom: 20px;
    left: 20px;
    animation: cookiePopup 1.8s ease-in-out forwards;

    @media all and (max-width: 768px) {
        padding: 15px;
    }

    &:not(.open) {
        display: none;
    }

    * {
        color: #fff;
        will-change: transform;
    }

    &__close {
        width: 20px;
        position: absolute;
        top: 25px;
        right: 15px;
        cursor: pointer;
        transition: opacity .3s linear;

        &::before,
        &::after {
            content: "";
            width: 18px;
            height: 3px;
            background: #fff;
            border-radius: 2px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%) rotate(45deg);
            transform-origin: center;
        }
        &::after {
            transform: translateY(-50%) rotate(-45deg);
        }
        &:hover {
            opacity: .7;
        }
    }

    &__title {
        font-size: 16px;
        margin: 0 0 .8em;
        @media all and (max-width: 768px) {
            font-size: 14px;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 1.7;
        @media all and (max-width: 768px) {
            font-size: 13px;
        }
    }

    &__menu {
        display: flex;
        justify-content: flex-end;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            font-size: 14px;
            line-height: 1.2;

            & + li {
                margin-left: 10px;
                padding-left: 10px;
                border-left: 1px solid #fff;
            }

            a {
                cursor: pointer;
                transition: opacity .3s linear;

                &:hover {
                    opacity: .7;
                }
            }
        }
    }

}

@keyframes cookiePopup {
    0% {
        bottom: -50%;
    }
    100% {
        bottom: 20px;
    }
}

.cookie-policy {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .2s linear;

    &:not(.open) {
        opacity: 0;
        pointer-events: none;
    }

    a {
        color: #000;
        text-decoration: underline;
    }

    &__layer {
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.6);
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }

    &__container {
        display: flex;
        flex-direction: column;
        width: calc(100% - 40px);
        max-width: 800px;
        height: calc(100% - 40px);
        max-height: 630px;
        overflow: auto;
        background: #fff;

        @media all and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
        }

        &__title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100px;
            padding: 20px 50px 20px 20px;
            box-sizing: border-box;
            border-bottom: 2px solid #cccccc;

            @media all and (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;
                height: auto;
            }

            &__detail {
                font-size: 20px;
                @media all and (max-width: 768px) {
                    font-size: 14px;
                    margin-top: 7px;
                }
            }
        }

        &__flex {
            display: flex;
            flex-wrap: wrap;
            flex: 1;

            @media all and (min-width: 769px) {
                min-height: 450px;
            }

            &__left {
                width: 260px;

                @media all and (max-width: 768px) {
                    width: 100%;
                }

                &__menu {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    li {
                        border-bottom: 1px solid #cccccc;

                        > a {
                            display: block;
                            font-size: 16px;
                            color: #000000;
                            text-decoration: none;
                            padding: .8em 1em .8em 2em;
                            box-sizing: border-box;
                            background: #eeeeee;
                            cursor: pointer;
                            transition: opacity .3s linear;

                            @media all and (max-width: 768px) {
                                font-size: 14px;
                                padding: .8em 1em .8em 18px;
                            }


                            &.active {
                                background: #fff;
                                border-left: 5px solid #cccccc;
                                opacity: 1!important;
                                cursor: auto;
                            }
                            &:hover {
                                opacity: .5;
                            }
                        }
                    }
                }
            }

            &__right {
                width: calc(100% - 260px);
                padding: 35px 40px;
                box-sizing: border-box;

                @media all and (max-width: 768px) {
                    display: none;
                }

                &__contents {
                    &__box {
                        position: relative;

                        @media all and (max-width: 768px) {
                            padding: 20px;
                            border-top: 1px solid #cccccc;
                        }

                        &:not(.active) {
                            display: none;
                        }

                        &__field {
                            position: absolute;
                            right: 0;
                            top: 0;

                            @media all and (max-width: 768px) {
                                font-size: 12px;
                                top: 20px;
                                right: 20px;
                            }

                            input {
                                margin: 0 5px 0 0;
                                position: relative;
                                top: 1px;
                            }
                        }


                        &__title {
                            font-size: 18px;
                            font-weight: 500;
                            margin: 0 0 2em;

                            @media all and (max-width: 768px) {
                                font-size: 14px;
                                margin: 0 0 1em;
                            }
                        }

                        &__text {
                            p {
                                font-size: 14px;
                                line-height: 1.71;
                                text-align: justify;
                                margin: 0 0 1em;

                                @media all and (max-width: 768px) {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__bottom {
            width: 100%;
            padding: 15px;
            height: 82px;
            box-sizing: border-box;
            background: #eeeeee;

            &__btn {
                -webkit-appearance: none;
                border: none;
                outline: none;

                width: 240px;
                color: #fff;
                font-size: 16px;
                font-weight: 600;
                text-align: center;
                padding: 14px 0;
                border-radius: 5px;
                background: #000;
                cursor: pointer;
                transition: opacity .3s linear;

                &:hover {
                    opacity: .7;
                }
            }
        }
    }
}
